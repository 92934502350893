import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import styles from './locations_tiles.module.css'
import translations from './locations_tiles.translations'
import { useTranslation } from '../../i18n'

const LocationsTiles = () => {
  const t = useTranslation(translations)
  const locations = Object.keys(t.offices)

  return (
    <div className={styles.locations}>
      {locations.map((loc) => {
        const office = t.offices[loc]

        return (
          <div className={styles.location} key={loc}>
            <div className={styles.illustration}>
              <img loading='lazy' src={office.imageSrc} alt={office.imageAltText} />
            </div>
            <span className={styles.city}>{office.city}</span>
            <span>{office.country}</span>

            <AnchorWithArrow className={styles.link} href={office.ctaHref} trackingId='office' trackingLabel={loc}>
              {office.ctaText}
            </AnchorWithArrow>
          </div>
        )
      })}
    </div>
  )
}

export default LocationsTiles
