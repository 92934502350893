import { frontdoorPaths } from '../../routing/paths'
import melbourne from './melbourne.png'
import guadalajara from './guadalajara.png'

export default {
  en: {
    title: 'Our Offices',
    offices: {
      melbourne: {
        city: 'Australia & New Zealand',
        country: 'Oceania',
        imageSrc: melbourne,
        imageAltText: 'Melbourne line illustration',
        ctaHref: frontdoorPaths.aboutCareersMelbourne,
        ctaText: 'Visit AU/NZ'
      },
      guadalajara: {
        city: 'Guadalajara',
        country: 'Mexico',
        imageSrc: guadalajara,
        imageAltText: 'Guadalajara line illustration',
        ctaHref: frontdoorPaths.aboutCareersGuadalajara,
        ctaText: 'Visit Guadalajara'
      }
    }
  },
  es: {
    title: 'Nuestras Oficinas',
    offices: {
      melbourne: {
        city: 'Australia y Nueva Zelanda',
        country: 'Oceanía',
        imageSrc: melbourne,
        imageAltText: 'Dibujo lineal de Melbourne',
        ctaHref: frontdoorPaths.aboutCareersMelbourne,
        ctaText: 'Visita AU/NZ'
      },
      guadalajara: {
        city: 'Guadalajara',
        country: 'México',
        imageSrc: guadalajara,
        imageAltText: 'Dibujo lineal de Guadalajara',
        ctaHref: frontdoorPaths.aboutCareersGuadalajara,
        ctaText: 'Visita Guadalajara'
      }
    }
  }
}
