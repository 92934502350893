import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import PropTypes from 'prop-types'
import Text from '../text/text'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import { hrefPropTypes } from '../hyperlink/hyperlink'
import styles from './locations.module.css'
import LocationsTiles from '../locations_tiles/locations_tiles'

const Locations = ({ titleText, supportingText, linkHref, linkLabel }) => (
  <CenteredSectionWrapper>
    <div className={styles.root}>
      <div className={styles.titleBlock}>
        <h2>
          <Text type='heading' block>
            {titleText}
          </Text>
        </h2>
        <p>
          <Text type='supporting' block>
            {supportingText}
          </Text>
        </p>
        <p>
          <AnchorWithArrow href={linkHref} className={styles.link}>
            {linkLabel}
          </AnchorWithArrow>
        </p>
      </div>

      <LocationsTiles />
    </div>
  </CenteredSectionWrapper>
)

Locations.propTypes = {
  titleText: PropTypes.string.isRequired,
  supportingText: PropTypes.string.isRequired,
  linkHref: hrefPropTypes.isRequired,
  linkLabel: PropTypes.string.isRequired
}

export default Locations
